import { useStaticQuery, graphql } from 'gatsby';

const useAboutResponsiveImage = (img) => {
    const { file1, file2 } = useStaticQuery(
        graphql`
            query aboutImage {
                file1: file(relativePath: { eq: "baiba2.jpg" }) {
                    childImageSharp {
                        ...lgImageFluid
                    }
                }
                file2: file(relativePath: { eq: "baiba_main.jpg" }) {
                    childImageSharp {
                        ...lgImageFluid
                    }
                }
            }
        `,
    );

    if (img === 1) return file1.childImageSharp.fluid;
    else return file2.childImageSharp.fluid;
};

export default useAboutResponsiveImage;
