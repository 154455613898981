import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Card } from '@material-ui/core';
import React from 'react';

// const bgCardColor = (props) => props?.theme?.HeroCard?.backgroundColor ?? 'inherit';
// const textColor = (props) => props?.theme?.HeroCard?.color ?? 'inherit';

const StyledCard = styled.span`
    color: ${(props) => props?.theme?.HeroCard?.color ?? 'inherit'};
    background-color: ${(props) => props?.theme?.HeroCard?.backgroundColor ?? 'inherit'};
    min-height: ${(props) => props.minHeight};
    max-height: ${(props) => props.maxHeight};
    display: flex;
    height: 100%;
`;

const HeroCardStyles = ({ children, cardHeights = ['auto', 'auto'] }) => {
    return (
        <StyledCard minHeight={cardHeights[0]} maxHeight={cardHeights[1]}>
            <Grid container component={Card}>
                {children}
            </Grid>
        </StyledCard>
    );
};

export default HeroCardStyles;
