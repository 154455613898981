import React from 'react';
import Theme from '../components/PageCommon/Theming/ThemeProvider';
import PageContainer from '../components/PageCommon/PageContainer/PageContainer';
import PageMeta from '../components/PageCommon/PageMeta/PageMeta';
import Footer from '../components/PageCommon/Footer/Footer';
import MainContainer from '../components/PageCommon/MainContainer/MainContainer';
import NavBar from '../components/PageCommon/NavBar/NavBar';
import HeroCard from '../components/Cards/HeroCard/HeroCard';
import BasicCard from '../components/Cards/BasicCard/BasicCard';

import { Grid } from '@material-ui/core';
//import CustomLink from '../components/Cards/Common/CustomLink';

import useAboutResponsiveImage from '../hooks/useAboutResponsiveImage';

const heroTitle = 'SVEIKI, ESMU BAIBA!';
const heroText = `Te dalos pārdomās, pieredzē, iespaidos un atklāsmēs par mani interesējošām tēmām, tādām kā veselība, ģimene, karjera, ceļojumi, un kā to visu veiksmīgi apvienot.
Pati esmu precējusies 3 bērnu mamma, esmu bijusi neliela uzņēmuma vadītāja, izmēģinājusi roku pati savu projektu īstenošanā, daudz laika veltu sevis izzināšanai un pilnveidošanai.
Īpašu uzmanību veltu tieši tēmām par veselīgu dzīvesveidu un uzturu.`;
const heroSubTitle = 'Laipni lūdzu manā bloga lapā!';

const addTitleA = `MANS STĀSTS`;
const addTextA = `Ikdienā man ir daudzas lomas - mamma, meita, sieva, draudzene. Pāri tam visam cenšos būt arī pašpietiekama sieviete. Man ļoti patīk būt kopā ar ģimeni, ceļot, mācīties un paplašināt savu redzes lauku. Katru dienu tiecos būt sevis labāka versija, salīdzinot ar iepriekšējo dienu.
Mans dzīves ceļš ir metis līkločus caur kosmētiķa profesijas apgūšanu, kvalitātes vadību, uzņēmējdarbību, kas diemžēl noveda arī pie sevis pārdedzināšanas gan fiziski, gan garīgi. Kad veselība sāka dot mājienus, ka "ir par daudz", sāku pastiprināti interesēties par to, ko pati varu sevis labā darīt. Tā nonācu pie ”life coaching” un NLP pamatnostādņu apgūšanas, ikdienas efektīvas plānošanas, pozitīvu ieradumu nostiprināšanas un veselīga dzīvesveida veidošanas.`;

const addTitleB = `IEPAZĪŠANĀS AR "CORAL CLUB"`;
const addTextB = `Līdz "Coral Club" produktiem nonācu vajadzības dzīta. Tolaik bērni ļoti bieži slimoja ar klepu un iesnām, kas ilgi nepārgāja, pārauga iekaisumos, un tāpēc regulāri tika izrakstīti antibiotiku kursi. Tas bija laiks, kad visus trīs bērnus bija skāris garais klepus, kā arī vecākajam dēlam (4 gadi) tika rekomendēta adenoīdu un pēcāk arī vidusauss operācija. Gāja smagi, it īpaši naktīs. Kaut kas bija jādara! Meklēju veidus, kā bērniem uzlabot imunitāti un noturību pret šīm kaitēm. Kādā brīdī izlēmu pamēģināt, lai pårbaudītu- vai mums palīdzēs?
Man satsādīja pārdomātu Coral produktu programmu, ko kā eksperimentu disciplinēti un konsekventi ievērojām. Un bērni sāka slimot mazāk. Saaukstēšanās epizodes šad tad parādījās, bet nu jau pārgāja krietni ātrāk un vieglāk. Vecākajam dēlam lai arī nebija pilnīga auss izveseļošanās, tomēr bija vērā ņemami uzlabojumi- pietiekami, lai varētu iztikt bez rekomendētajām operācijām (regulāri atrādāmies pie BKU ārsta). No tā laika arī turpinam lietot "Coral Club" produktus profilaktiskos nolūkos.`;

// const rsuUrl = <CustomLink href="https://">Link text</CustomLink>;
const addTitleC = `MOTIVĀCIJA NĀKOTNEI`;
const addTextC = () => (
    <span>
        Tādu uzlabojumu tikai no dabiskiem uztura bagātinātājiem es tiešām negaidīju (pilnvērtīgu un veselīgu ēšanu un
        daudzkustīgu dzīvesveidu mēs piekopām jau tāpat), tādēļ nolēmu arī pati papildināt zināšanas par uzturu un
        veselību kopumā. Palīdzot savai ģimenei smēlos iedvesmu mēģināt palīdzēt arī citiem, tādēļ sāku dalīties ar savu
        pieredzi- rakstīt blogu, piedalīties diskusijās, organizēt un vadīt lekcijas par uzturu un veselīgu dzīvesveidu.
        Viss sākas ar attieksmi pret sevi pašu un vēlmi justies labi tagad un arī sirmā vecumā! Es izvēlos novecot
        kvalitatīvi, vairot pozitīvismu, būt iekšējā merā ar sevi un, cerams, kaut nedaudz palīdzēt to sasniegt arī
        citiem.
    </span>
);

const AboutPage = () => {
    const fluid = useAboutResponsiveImage(1);

    const pageTitle = 'Par mani';

    return (
        <Theme>
            <PageMeta title={pageTitle} />
            <PageContainer>
                <NavBar page="about" />
                <MainContainer>
                    <Grid container spacing={1}>
                        <Grid item style={{ width: '100%' }}>
                            <HeroCard
                                img={fluid}
                                title={heroTitle}
                                text={heroText}
                                subTitle={heroSubTitle}
                                split={false}
                            />
                        </Grid>

                        <Grid item style={{ width: '100%' }}>
                            <BasicCard title={addTitleA} text={addTextA} />
                        </Grid>
                        <Grid item style={{ width: '100%' }}>
                            <BasicCard title={addTitleB} text={addTextB} />
                        </Grid>
                        <Grid item style={{ width: '100%' }}>
                            <BasicCard title={addTitleC} text={addTextC()} />
                        </Grid>
                    </Grid>

                    {/* <AboutLayout
                        // titleCard={titleCard}
                        heroCard={ThisHeroCard}
                        otherCards={[textCardA, textCardB, textCardC]}
                    /> */}
                </MainContainer>
                <Footer />
            </PageContainer>
        </Theme>
    );
};

export default AboutPage;
