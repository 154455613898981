import styled from 'styled-components';

const textColor = (props) => props?.theme?.CustomLink?.color ?? 'inherit';
const font = (props) => props?.theme?.CustomLink?.fontFamily ?? 'inherit';
const hoverColor = (props) => props?.theme?.CustomLink?.hover?.color ?? 'inherit';
const fontSize = (props) => props?.theme?.CustomLink?.fontScale ?? '1em';

const CustomLinkStyles = styled.a`
    color: ${textColor};
    font-family: ${font};
    font-size: ${fontSize};
    text-decoration: none;
    cursor: pointer;
    transform: scale(1.05);

    &:hover {
        color: ${hoverColor};
        text-decoration: none;
        transition: transform 0.1s;
    }
`;

export default CustomLinkStyles;
