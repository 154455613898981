import React from 'react';

import HeroCardStyles from './HeroCardStyles';
import HeroImg from './HeroImg';
import BlogTitle from '../Common/BlogTitle';
import BlogText from '../Common/BlogText';
import SubTitle from '../Common/SubTitle';
import CardTextContainer from '../Common/CardTextContainer';
import PostDate from '../Common/PostDate';
import CustomLink from '../Common/CustomLink';
import ReadMore from '../Common/ReadMore';
import ThemeLabel from '../Common/ThemeLabel';

import { Grid } from '@material-ui/core';

import useResponsiveSizing from '../../../hooks/useResponsiveSizing';

// to fit full screen height is some screen sizes where possible max len also is present
const cardHeights = [
    ['calc(100vh - 4.9rem)', 'auto'],
    ['calc(100vh - 5.4rem)', 'auto'],
    ['39vw', 'auto'],
    ['500px', 'auto'],
    ['500px', 'auto'],
];

const HeroCard = (props) => {
    const title = props.title ?? '';
    const text = props.text ?? '';
    const subTitle = props.subTitle ?? '';
    const date = props.date ?? '';
    const img = props.img ?? '';
    const split = props.split ?? false;
    const linkText = props.linkText ?? '';
    const linkHref = props.linkHref ?? '';
    const themes = props.themes ?? [];
    // const siteUrl = props.siteUrl ?? '';
    const themeBaseUrl = '/themes/';
    const breakPointText = split ? 5 : 12;
    const breakPointImg = split ? 7 : 12;

    return (
        <HeroCardStyles cardHeights={useResponsiveSizing(cardHeights)}>
            {/* important top set width otherwise gatsby-img will not work properly */}
            <Grid item md={breakPointImg} style={{ width: '100%' }}>
                <HeroImg alt="Illustrative image related to post" fluid={img} title={title} split={split} />
            </Grid>
            {/* this combo does not break ver centering and allows hor centering */}
            <Grid item md={breakPointText} style={{ display: 'flex' }}>
                <CardTextContainer>
                    {themes.map((el, idx) => (
                        <ThemeLabel key={idx} href={themeBaseUrl + el}>
                            {el}
                        </ThemeLabel>
                    ))}
                    <BlogTitle gutterBottom={true}>{title}</BlogTitle>
                    <SubTitle gutterBottom={true}>{subTitle}</SubTitle>
                    <PostDate gutterBottom={true}>{date}</PostDate>
                    <BlogText>{text}</BlogText>
                    <ReadMore align="right">
                        <SubTitle>
                            <CustomLink href={linkHref}>{linkText}</CustomLink>
                        </SubTitle>
                    </ReadMore>
                </CardTextContainer>
            </Grid>
        </HeroCardStyles>
    );
};

export default HeroCard;
