import React from 'react';
import { Typography } from '@material-ui/core';

const ReadMore = ({ children, align = 'inherit', style = {} }) => (
    <Typography align={align} variant="h6" component="p" style={style}>
        {children}
    </Typography>
);

export default ReadMore;
